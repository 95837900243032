import React from "react";
import { BsFacebook, BsTwitter, BsInstagram, BsYoutube } from 'react-icons/bs';

const Footer = () => {
    return (
        <div>
            <div className='m-5'>
            </div>
            <hr></hr>
            <div className="text-center">
                <a class="m-2 display-3 text-info" href="https://www.facebook.com/laselvaoperaorg/" role="button"><i class="bi bi-facebook"></i></a>
                <a className="m-2 display-3 text-secondary" href="https://www.twitter.com/Laselvaopera"><i class="bi bi-twitter"></i></a>
                <a className="m-2 display-3 text-danger" href="https://www.instagram.com/Laselvaopera"><i class="bi bi-instagram"></i></a>
            </div>
        </div>
    )
}
export default Footer;