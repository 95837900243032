import { React, useState } from "react";
import Modal from 'react-bootstrap/Modal';
const About = () => {
    // const [donate, setDonate] = useState(false);
    // const [fullscreen, setFullscreen] = useState(true);
    // const [learn, setLearn] = useState(false);

    // function handleDonate(breakpoint) {
    //     setFullscreen(breakpoint);
    //     setDonate(true);
    // }
    // function handleLearn(breakpoint) {
    //     setFullscreen(breakpoint);
    //     setLearn(true);
    // }
    return (
        <div>
            {/* <button className="btn btn-primary" href="#donate" onClick={handleDonate}>donate</button> */}
            <div className="mb-5">
                <div >
                    <div className="m-5">
                        <iframe className="rounded mx-auto d-block youtube-video" src="https://www.youtube.com/embed/EF5cgN39cnQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className="fs-5">
                        <p>Vincent La Selva had a remarkable mission: to make opera accessible to all. His groundbreaking achievements remain unparalleled, as no one before or after him has been able to replicate his success. With unwavering determination, he brought fully staged operas, accompanied by full orchestras, to the masses in the enchanting Central Park of New York. Millions of New Yorkers attended with between 10,000 and 20,000 at every performance. Since his passing in 2017, a void has been left in the classical music scene in New York, yearning to be filled.</p>
                        <p>Vincent La Selva's unparalleled passion for bringing opera to the people was truly unmatched. For over three decades, he selflessly dedicated his own resources, relying on grants, donations, and corporate sponsorships to make his vision a reality. His legacy is a testament to his unwavering commitment to the arts and the community.</p>
                        <p>As the daughter of Maestro La Selva, I am determined to revive his noble mission and reintroduce free opera to the people of New York. However, I aspire to expand beyond Central Park and bring this cultural experience to other major cities across the country. The impact of his work was so profound that it left an indelible mark on the hearts of many, including his devoted followers, fans, and colleagues. Their enthusiasm and energy are palpable, igniting a renewed sense of excitement for the formation of the non-profit, La Selva Opera.</p>
                        <p>Even today, WQXR, New York's esteemed classical station, continues to honor Maestro's legacy by regularly playing his CDs. The enduring interest in his work is undeniable, but we require the support of generous supporters, sponsors, and grants to make this revival a reality. The name La Selva carries with it a promise of exhilaration and fervor, ensuring that there will be no shortage of excitement and interest in this endeavor.</p>
                        <p>Together, let us embark on this journey to bring back the magic of free opera to the people. Let us honor Vincent La Selva's extraordinary legacy and make his dream a living reality once more.</p>
                    </div>
                </div>
                <div className="text-center">
                    <h2 className="modal_details mb-3">Donate to</h2>
                    <h1 className="modal_details">LA SELVA OPERA</h1>
                    <a className="btn btn-primary btn-lg m-3" href="https://www.paypal.com/donate/?hosted_button_id=M57W97U7D9MHY">PayPal</a>
                    <a className="btn btn-primary btn-lg m-3" href="https://account.venmo.com/u/Laselvaopera">Venmo</a>
                    <p>100% of your donation goes to the company and is tax deductible.</p>
                    <h6>501(c)3 Pending</h6>
                </div>
            </div>


            <div className="spacingTitles">
                <h1 className="PageTitles">who we are</h1>
                <div className="mb-5" id="whoweare"></div>
                <div className="mb-5 p-2">
                    <h3 className="mx-3">Maria La Selva Vogt</h3>
                    <h4 className="modal_title mx-3">Founder and General Director</h4>
                    <img className="img-fluid rounded mx-auto d-block p-2" src="./assets/images/Maria_La_Selva_Vogt.jpg" alt="Maria La Selva"></img>
                    <div className="modal_body_Cast fs-5 m-auto my-5 text-wrap">
                        <p>For Maria La Selva Vogt, daughter of the world-renowned conductor Vincent La Selva, honoring her father’s work is the least that she could do to continue his musical legacy and rekindle the embers of his burning vision. And according to Maria, each day of her life is meaningful since it reflects her late father’s passion and the imprints that it left in her heart.</p>
                        <p>Maestro La Selva gained recognition for being the esteemed founder and director of the New York Grand Opera, the third major opera company in New York. Over three million people attended his performances over the years. He was also knighted by the President of Italy and awarded the coveted Handel Medallion by then-Mayor of NYC Rudolph Giuliani. And because of his outstanding performance and reputable talent, he was often compared to the late- great Arturo Toscanini. He was awarded the world record for the chronological presentation of all of Verdi’s operas, ending with the Requiem Mass at Carnegie Hall on the 100th anniversary of Verdi’s death on January 27, 2001. Maestro La Selva was known as the best conductor of Verdi in the world.</p>
                    </div>
                </div>
                <div className="mb-5 p-2">
                    <h3 className="mx-3">Constantine Kitsopoulos</h3>
                    <h4 className="modal_title mx-3">Music Director</h4>
                    <img className="img-fluid rounded mx-auto d-block p-2" src="./assets/images/Constantine_Kitsopolous.jpg" alt="Constantine Kitsopoulos"></img>
                    <div className="modal_body_Cast fs-5 m-auto my-5 text-wrap">
                        <p>Constantine Kitsopoulos has established himself as a dynamic conductor known for his ability to work in many different genres and settings. Equally at home in opera, symphonic repertoire, film with live orchestra, music theater, and composition, his work has taken him all over the world, where he has conducted the major orchestras of North America, the Hong Kong Philharmonic, and Tokyo Philharmonic. In addition to Kitsopoulos’s engagements as guest conductor, he is music director of the Festival of the Arts Boca and general director of Chatham Opera. He is general director of New York Grand Opera and is working with the company to bring opera, free and open to the public, back to New York’s Central Park.</p>
                        <p>In 2022–23 Kitsopoulos makes his debut with the Chicago Symphony Orchestra, and conducts return engagements with the New York Philharmonic, The Philadelphia Orchestra, and the Detroit, Phoenix, Houston, Vancouver, New Jersey, and San Francisco symphony orchestras. Previous season highlights include returns to the Dallas, Detroit, New Jersey, Phoenix, and Toronto symphony orchestras, the San Francisco, San Antonio, Houston symphonies, and the New York Philharmonic and Louisiana Philharmonic. Kitsopoulos also conducted Leonard Bernstein’s Mass at Indiana University Opera Theater.</p>
                        <p>Constantine Kitsopoulos has developed semi-staged productions of Mozart’s The Magic Flute (for which he has written a new translation) and Don Giovanni, and Puccini’s La bohème. He has conducted IU Opera Theatre’s productions of Verdi’s Falstaff, J. Strauss II’s Die Fledermaus, William Bolcom’s A View from the Bridge, Gilbert and Sullivan’s H.M.S. Pinafore, Loesser’s The Most Happy Fella, Rodgers and Hammerstein’s South Pacific and Oklahoma, Willson’s The Music Man, and Menotti’s The Last Savage. He was assistant chorus master at New York City Opera from 1984–89. On Broadway Kitsopoulos has been music director of Rodgers & Hammerstein’s Cinderella, The Gershwins’ Porgy and Bess (cast album on PS Classics), John Bucchino’s A Catered Affair(cast album on PS Classics), Adrian Sutton’s Coram Boy, Baz Luhrmann’s production of Puccini’s La Bohème (cast album on DreamWorks Records), Tchaikovsky’s Swan Lake, and Claude-Michel Schönberg’s Les Misérables. He was music director of ACT’s production of Weill / Brecht’s Happy End and made the only English language recording of the piece for Sh-K-Boom Records.</p>
                        <p>Constantine Kitsopoulos studied piano with Marienka Michna, Chandler Gregg, Edward Edson, and Sophia Rosoff. He studied conducting with Semyon Bychkov, Sergiu Commissiona, Gustav Meier, and his principal teacher, Vincent La Selva.</p>
                    </div>
                </div>
                <div className="mb-5 p-2">
                    <h3 className="mx-3">Michael Hajek</h3>
                    <h4 className="modal_title mx-3">Artistic Director</h4>
                    <img className="img-fluid rounded mx-auto d-block p-2" src="./assets/images/Blank.png" alt="Maria La Selva"></img>
                    <div className="modal_body_Cast fs-5 m-auto my-5 text-wrap">
                        <p> </p>
                        <p> </p>
                    </div>
                </div>
            </div>

            {/* <Modal
                    show={learn}
                    backdrop="static"
                    keyboard={false}
                    centered
                    onHide={() => setLearn(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className={"modal_title"}><text>donate</text></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center modal_body">
                        <h2 className="modal_details mb-3">Donate to</h2>
                        <h1 className="modal_details">LA SELVA OPERA</h1>
                        <a className="btn btn-primary btn-lg disabled m-3" href="https://paypal.me/">PayPal</a>
                        <a className="btn btn-primary btn-lg m-3" href="https://account.venmo.com/u/Laselvaopera">Venmo</a>
                        <div className="modal_body">
                            <p>Help us keep the La Selva mission of bringing free and low cost opera to the masses by donating here.</p>
                            <p>100% of your donation goes to the company and is tax deductible.</p>
                            <h6> 501(c)3 Pending</h6>
                        </div>

                    </Modal.Body>
                </Modal>
                <Modal
                    show={learn}
                    // fullscreen={fullscreen}
                    backdrop="static"
                    keyboard={false}
                    centered
                    onHide={() => setLearn(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className={"modal_title"}>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"modal_body"}>{lngDescript}</Modal.Body>
                </Modal> */}
        </div>
    );
}
export default About;





