import { React, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import About from "./About";

const NavBar = () => {
    const [about, setAbout] = useState(false);
    const [donate, setDonate] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);
    function handleStory(breakpoint) {
        setFullscreen(breakpoint);
        setAbout(true);
    }

    function handleDonate(breakpoint) {
        setFullscreen(breakpoint);
        setDonate(true);
    }

    return (
        <div className="navbar fixed-top bg-white">
            <Navbar collapseOnSelect expand="lg" variant="light" className="d-flex bg-white">
                <Navbar.Brand className="bg-white navSpaceMobile">
                    <a className="navbar-brand bg-white" href="#top" >
                        <img src="./assets/images/LSO-SmLogo.png" className="LogoMobile mx-2 bg-white" alt="" width="48" height="48"></img>
                        {/* <img src="./assets/images/LSO-LgLogo.png" className="LogoMobile pb-1 bg-white" alt="" height="48"></img> */}
                    </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="bg-white mx-3 " />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end navMobileCenter bg-white">
                    <Nav className="bg-white mx-2 fontFam">
                        <Nav.Link href="#top">home</Nav.Link>
                        <Nav.Link href="#events">events</Nav.Link>
                        <Nav.Link href="#about" onClick={handleStory}>about</Nav.Link>
                        <Nav.Link href="#history">history</Nav.Link>
                        <Nav.Link href="#donate" onClick={handleDonate}>donate</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Modal
                    show={about}
                    backdrop="static"
                    fullscreen={fullscreen}
                    keyboard={false}
                    centered
                    onHide={() => setAbout(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className={"modal_title"}><text>
                            <div className='PageTitles' variant="light" >
                                <h1>about</h1>
                            </div>
                        </text></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                        <h3 className="modal_body">
                            <div><About/></div>
                        </h3>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={donate}
                    backdrop="static"
                    keyboard={false}
                    centered
                    onHide={() => setDonate(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className={"modal_title"}><text>donate</text></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center modal_body">
                        <h2 className="modal_details mb-3">Donate to</h2>
                        <h1 className="modal_details">LA SELVA OPERA</h1>
                        <a className="btn btn-primary btn-lg m-3" href="https://www.paypal.com/donate/?hosted_button_id=M57W97U7D9MHY">PayPal</a>
                        <a className="btn btn-primary btn-lg m-3" href="https://account.venmo.com/u/Laselvaopera">Venmo</a>
                        <div className="modal_body">
                            <p>Help us keep the La Selva mission of bringing free and low cost opera to the masses by donating here.</p>
                            <p>100% of your donation goes to the company and is tax deductible.</p>
                            <h6> 501(c)3 Pending</h6>
                        </div>

                    </Modal.Body>
                </Modal>
            </Navbar>
        </div>
    );
}
export default NavBar;