import { React, useState } from "react";
import Card from "react-bootstrap/Card";
import Modal from 'react-bootstrap/Modal';

const EventCards = (props) => {
    const title = props.title
    const art = props.art
    const date = props.date
    const shtDescript = props.shtDescript
    const lngDescript = props.lngDescript
    const ticketLink = props.ticketLink
    const castList = props.castList
    const [fullscreen, setFullscreen] = useState(true);
    const [learn, setLearn] = useState(false);
    const [cast, setCast] = useState(false);
    const [tickets, setTickets] = useState(false);

    const castListDetails = castList.map(item =>
        <div className="mb-5" key={item.id} >
            <div>
                <h3 className="modal_title">{item.name}
                </h3>
                <div className="mx-4 px-4">
                    <img className="img-fluid rounded mx-auto d-block p-3"
                        src={item.photo}
                        alt={item.name} />
                </div>
                <p className="modal_body_Cast">{item.castDescript}</p>
            </div>
        </div>
    );

    function handleLearn(breakpoint) {
        setFullscreen(breakpoint);
        setLearn(true);
    }

    function handleCast(breakpoint) {
        setFullscreen(breakpoint);
        setCast(true);
    }

    function handleTickets(breakpoint) {
        setFullscreen(breakpoint);
        setTickets(true);
    }

    return (
        <div className="textBody text-dark bg-light card h-100 ">
            <div>
                <img className="card-img-top p-4" variant="top" src={art} alt="Event Art" />
                <Card.Body >
                    <Card.Title>{title}</Card.Title>
                    <Card.Subtitle className="mb-4 text-muted">{date}</Card.Subtitle>
                    <Card.Text>{shtDescript}</Card.Text>
                    <div className="d-flex justify-content-around mt-4">
                        <button type="button" className="btn btn-outline-secondary" variant="primary" onClick={handleLearn}>Learn More</button>
                        <button type="button" className="btn btn-outline-secondary" variant="primary" onClick={handleCast}>Cast</button>
                        <button type="button" className="btn btn-outline-secondary" variant="primary" onClick={handleTickets}>Tickets</button>
                    </div>
                </Card.Body>
                <Modal
                    show={learn}
                    // fullscreen={fullscreen}
                    backdrop="static"
                    keyboard={false}
                    centered
                    onHide={() => setLearn(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className={"modal_title"}>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"modal_body"}>{lngDescript}</Modal.Body>
                </Modal>
                <Modal
                    show={cast}
                    fullscreen={fullscreen}
                    onHide={() => setCast(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className={"modal_title"}>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={""}>{castListDetails}</Modal.Body>
                </Modal>
                <Modal
                    show={tickets}
                    backdrop="static"
                    keyboard={false}
                    centered
                    onHide={() => setTickets(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className={"modal_title"}><text>Tickets to {title}</text></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center modal_body">
                        <h3 className="modal_body mb-3">You are now leaving</h3>
                        <h1 className="modal_details">LA SELVA OPERA</h1>
                        <h3 className="modal_body">to accept click</h3>
                        <button type="button" className="btn btn-light btn-lg m-3"><a href={ticketLink}>Continue</a></button>
                    </Modal.Body>
                </Modal>
            </div></div>
    );
}
export default EventCards;





