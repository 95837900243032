import React from "react";
import { Parallax } from "react-parallax";

const image1 = "./assets/images/LaSelvaOperaLogo.png";

const Hero = () => {
    return (
        <div>
             <img className="img-fluid text-center m-2 p-5 w-100 hero" src={image1}></img>

            {/* <div className='hero'> */}
                {/* <Parallax 
                    bgImage={image1}
                    strength={100}
                    className="img-fluid p-5"
                >
                    <div className="paraSize">
                        <div className="centerContent">
                        </div>
                    </div>
                </Parallax>
            </div> */}
        </div>
    );
}
export default Hero;