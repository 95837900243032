import React from "react"
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import Events from "./components/Events";
import History from "./components/History";
// import Story from "./components/Story";
import Footer from "./components/Footer";
import './App.css';

function App() {
  return (
    <>
      <header></header>
      <div><NavBar /></div>
      <div className="spacingTitles" id="hero"></div>
      <div><Hero /></div>
      <div className="spacingTitles" id="events"></div>
      <div><Events /></div>
      {/* <div className="spacingTitles" id="story"></div>
      <div><Story /></div> */}
      <div className="spacingTitles" id="history"></div>
      <div><History /></div>
      <div><Footer /></div>
    </>
  );
}

export default App;
