import React from "react";
import { Parallax } from "react-parallax";
const image2 = "./assets/images/LaSelvaPhoto.JPG";

const History = () => {
    return (
        <div className="px-3">
            <div className='PageTitles' variant="light" >
                <h1>history</h1>
            </div>
            <div className="mt-5">
                <div className='p-3'>
                    <Parallax
                        bgImage={image2}
                        strength={200}
                        className="img-fluid p-5 flipImage"
                    >
                        <div className="paraSize">
                            <div className="centerContent">
                            </div>
                        </div>
                    </Parallax>
                </div>
            </div>
            <p className=" modal_body">
                Vincent la Selva was born September 17, 1929 in Cleveland, Ohio, and began performing as a trumpeter at the age of eight and by the age of 12, he was conducting student performances. He received his bachelor's degree from the Juilliard School.
                <br />
                <br />
                After his graduation from Juilliard, he served in the United States Army and conducted the First Army Band at Fort Jay on Governor’s Island. It was in 1954 that he founded the Xavier Symphony Society, made up of volunteer performers, which formed the kernel of his idea for presenting free productions. One of his early successes was Gian-Carlo Menotti’s The Saint of Bleecker Street, which the composer urged Maestro La Selva to bring to New York City Opera. La Selva’s successful production of the Menotti masterpiece led to his being hired to conduct the City Opera's orchestra full-time. In 1969 Maestro La Selva joined the faculty of the Juilliard School where—until 2010—he taught much sought-after conducting classes and opera workshops. For many years, until their demolition, Maestro La Selva gave private lessons at the legendary Carnegie Hall Studios.
                <br />
                <br />
                Maestro La Selva was the founder of New York Grand Opera in 1973, which for 39 years offered fully-staged opera productions free to the public, most of the productions in Central Park. Since the opening performance May 23, 1973 of La bohème, Maestro La Selva led 55 operas in upwards of 350 performances. Over the years, these performances were attended by more than three million people. In 2001 Mr. La Selva finished an unprecedented eight-year cycle of the complete Verdi operas—all twenty-eight of them—performed in chronological order with a special performance of Verdi’s Requiem at Carnegie Hall. He continued to perform Verdi and Puccini operas in Central Park until 2012 and symphonic concerts at Carnegie Hall and at the Church of St. Paul and St. Andrew in New York City.
                <br />
                <br />
                The New York Grand Opera’s presentations ranged from such standards as Aida, Rigoletto, Tosca, to rarities such as Leoncavallo’s La Bohème and Verdi’s Stiffelio in their U.S. premieres. New York Grand Opera gave the first fully staged performance in the United States of Verdi’s Giovanna D’Arco. The company also gave the first staged performances in New York of  Verdi’s Aroldo and Jérusalem, the first  New York staged performance in 127 years of his I Masnadieri, the first New York performance with orchestra of his earliest opera, Oberto; the first fully-staged performance of Verdi’s eighth opera Alzira; and the first company to perform both La Bohème operas in a single season.   Venturing indoors, The New York Grand Opera  played such diverse venues as Carnegie Hall, Lincoln Center’s Alice Tully Hall, the Beacon Theatre, and the Brooklyn Academy of Music.  It also gave free performances at Co-op City, Eisenhower Park (Long Island), Marine Park (Brooklyn), and the Bronx Botanical Gardens.
                <br />
                <br />
                In addition to his opera performances, Maestro La Selva earned special renown for conducting symphonic scores with directness, lyricism, and great passion. The noted critic B.H. Haggin praised La Selva in the following manner:  “It is clear that he has what Bernard Shaw has called the highest faculty of a conductor, the magnetic influence under which an orchestra becomes as amenable to the baton as a pianoforte to the fingers… and not only in orchestra but singers.”  He led acclaimed performances with the New Jersey Symphony, the Juilliard Symphony, the Brno State Philharmonic in the Czech Republic, the Bern Symphony in Switzerland, the Symphony of the Air (formerly the NBC Symphony,) and he was Music Director of the Greater Trenton Symphony Orchestra.  Among his collaborators were such soloists as Leonard Rose, Ruggiero Ricci, Zinka Milanov, Rudolf Firkusny, Murray Perahia, Renata Tebaldi, Franco Corelli, and Peter Serkin.                  </p>
        </div>
        
    );
}
export default History;
