import React from "react";
import EventCards from "./EventCards";
import eventArray from "../props/EventArray.json"

const Events = () => {
    return (
        <div className="px-3 ">
            <div className='PageTitles' variant="light" >
                <h1>events</h1>
            </div>
            {/* <h3 className="textBody m-3">Check back for more event updates.</h3> */}
            <div>
                <div className="container mt-5">
                    <div className="row row-cols-1 justify-content-evenly row-cols-md-3 g-4">
                        {eventArray.map((event, index) => {
                            return (
                                <div className="col col-sm-8 col-md-6 col-lg-4">
                                    <EventCards key={index}
                                        title={event.title}
                                        art={event.art}
                                        date={event.date}
                                        shtDescript={event.shtDescript}
                                        lngDescript={event.lngDescript}
                                        ticketLink={event.ticketLink}
                                        castList={event.castList}
                                    />
                                </div>
                            )
                        })
                        }</div>
                </div>
            </div>
        </div>
    );
}
export default Events;